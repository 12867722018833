nav {
    height: 100%;
    width: 225px;

    ul {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }

    img {
        width: 100px;
    }

    li {
        padding: 20px 50px 20px 20px;
        border: 1px solid $white;
        border-radius: 0 10px 10px 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        margin-bottom: -1px;

        &::before {
            content: '';
            position: absolute;
            left: -100%;
            bottom: 0;
            background-color: $color-004;
            height: 100%;
            width: 100%;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
            z-index: -1;
        }

        &:hover {
            &::before {
                transform: translateX(100%);
            }

            a {
                color: $color-006;
            }
        }

        &:has(img) {
            border: none;
            padding: 0;

            &::before {
                display: none;
            }
        }
    }

    a {
        font-family: $font-001;
        font-size: $font-xxl;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        transition: color 0.3s ease-in-out;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}