$color-001: #341ab3;
$color-002: #a348a6;
$color-003: #9f63c4;
$color-004: #9075d8;
$color-005: #cea2d7;
$color-006: #ff964f;
$white: #fff;
$black: #000;
$error: #ff6961;

$font-001: "Pixel";
$font-002: "Poppins";
$font-002--bold: "Poppins Bold";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$font-sm: 1.4rem;
$font-md: 1.8rem;
$font-xl: 2.1rem;
$font-xxl: 2.8rem;
$font-xxxxl: 10rem;