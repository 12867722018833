html {
    scroll-behavior: smooth;
    font-size: 10px;
}

body {
    font-size: $font-md;
    background: linear-gradient(45deg, $color-001, $color-002);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.container {
    margin: 0 auto;
    @include min($lg) {
        width: 70%;
    }
}

.page {
    display: flex;
    min-height: 100vh;
    padding: 50px 0;
}

.rich-text {
    font-family: $font-002;
    display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;

    * {
        line-height: 1.3;
        color: $black !important;
    }
}

b {
    font-weight: 600;
}

i {
    font-style: italic;
}