.btn {
    background-color: transparent;
    border-radius: 10px;
    display: block;
    padding: 15px 30px;
    text-align: center;
    border: none;
    font-family: $font-002;
    font-size: 1.8rem;
    cursor: pointer;
    
    &--primary {
        background-color: $color-005;

    }
}