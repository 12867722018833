.twitch-embed {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.live-stream {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin-bottom: 50px;
    }
}

.twitch-player {
    width: 70%;
    aspect-ratio: 1.77;
    box-shadow: 0px 0px 50px rgba($color: $color-005, $alpha: 1);
}