.giveaway__resume {
    font-family: $font-002;
    width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $color-003;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    h2 {
        font-size: $font-xl;
        font-family: $font-002--bold;
    }

    img {
        width: 100%;
    }

    button {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        height: 0;
        padding: 0;
    }

    &:hover {
        button {
            height: fit-content;
            opacity: 1;
            padding: 15px 30px;
        }
    }

    .overlay {
        display: none;
    }

    &.finished {
        cursor: not-allowed;
        
        .overlay {
            display: block;
            position: absolute;
            inset: 0 0 0 0;
            background-color: rgba($color: $black, $alpha: 0.4);
            width: 100%;
            height: 100%;
            z-index: 999;
            border-radius: 10px;

            p {
                position: absolute;
                padding: 10px;
                border-radius: 10px;
                background-color: $color-006;
                right: 0;
                top: 0;
                transform: translateX(10%) translateY(-50%);
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
        img {
            filter: grayscale(1);
        }
    }
}