.modal {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $color-001, $alpha: 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        width: 60vw;
        max-height: 90vh;
        overflow-y: auto;
        display: flex;
        align-items: center;
        gap: 50px;
        background-color: $color-003;
        padding: 20px;
        border-radius: 30px;
        position: relative;

        h2 {
            font-family: $font-002--bold;
            font-size: $font-xxl;
            margin-bottom: 20px;
        }

        .column {
            width: 50%;

            img {
                width: 100%;
                border-radius: 10px;
            }

            >svg {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                * {
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    * {
                        fill: $color-006;
                    }
                }
            }
        }
    }
}